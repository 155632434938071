import React, { useMemo, useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'
import loadable from '@loadable/component'
import { blogger, english, relationship } from '../utils/categoryCards'
import { sendAnalyticsEvent, AnalyticsData } from '../utils/ga'
import { Layout, HorizontalList } from '../components'
import { Button } from '../design'
import HeroIntroImage from '../images/hero_intro_picture.svg'
import ArcImage from '../images/arc.svg'
import LogoWhiteImage from '../images/logo_white.svg'
import LogoImage from '../images/logo.svg'
import IconCamera from '../images/icon_camera_64.svg'
import IconCourse from '../images/icon_course_64.svg'
import IconEyeClosed from '../images/eye-closed.svg'
import TrainerStep1Image from '../images/trainer-step1.svg'
import TrainerStep2Image from '../images/trainer-step2.svg'
import TrainerStep3Image from '../images/trainer-step3.svg'
import TrainerStep4Image from '../images/trainer-step4.svg'
import './index.scss'

// load Stories component dynamically to prevent Gatsby build html error
const Stories = loadable(() => import('react-insta-stories'))

const Home = () => {
  const goToCategories = label => {
    sendAnalyticsEvent('click', new AnalyticsData(label))
    navigate('/categories')
  }

  return (
    <Layout landing="true">
      {/* hero */}
      <div className="section-hero section-outer--gray">
        <div className="section-inner vertical-indent--m">
          <div className="section-hero__text">
            <h1>Научитесь уверенно говорить на камеру</h1>
            <div className="section-hero__buttons">
              <Button size="large" to="simulator" onClick={() => goToCategories('landing-try-button-top')}>
                Попробовать тренажер
              </Button>
            </div>
          </div>
          <div className="section-hero__image">
            <HeroIntroImage alt="Журналист берет интервью" />
          </div>
        </div>
      </div>
      {/* / hero */}

      <div className="section-product__arc">
        <ArcImage />
      </div>

      {/* product */}
      <div className="section-product section-outer--dark-gray">
        <div className="section-inner vertical-indent--m">
          <div className="section-product__intro">
            <LogoWhiteImage className="section-product__logo" />
            <h3>Новая интерактивная платформа для развития навыков общения и речи в формате видео интервью</h3>
          </div>
          <div className="section-product__features">
            <div className="section-product__feature">
              <div className="section-product__icon-container">
                <IconCamera className="section-product__icon" />
              </div>
              <p className="body-medium">
                Простой и удобный, а главное,{' '}
                <span className="section-product__feature--highlight">бесплатный тренажер</span> по развитию навыков
                общения и речи в формате видео интервью
              </p>
            </div>
            <div className="section-product__feature">
              <div className="section-product__icon-container">
                <IconCourse className="section-product__icon" />
              </div>
              <p className="body-medium">
                <span className="section-product__feature--highlight">Наборы вопросов</span> на самые разнообразные темы
                для тренировок на основе нашего тренажера
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* / product */}

      {/* for whom */}
      <div className="section-for-whom section-outer--gray">
        <div className="section-inner vertical-indent--m">
          <h2 className="section-for-whom__title">Вам это будет полезно, если</h2>
          <ul className="section-for-whom__list">
            <li className="section-for-whom__item">
              <p className="body-large section-for-whom__quote">Лучше я промолчу</p>
              <p className="body-medium">Вы чувствуете неуверенность в общении и речи</p>
            </li>
            <li className="section-for-whom__item">
              <p className="body-large section-for-whom__quote">Как бы тебе объяснить?</p>
              <p className="body-medium">Вы хотите просто и ясно доносить свои мысли</p>
            </li>
            <li className="section-for-whom__item section-for-whom__item--hide-on-mobile">
              <p className="body-large section-for-whom__quote">Эээ...</p>
              <p className="body-medium">Вы теряетесь при ответах на неожиданные вопросы</p>
            </li>
            <li className="section-for-whom__item">
              <p className="body-large section-for-whom__quote">Я вовсе не это имел ввиду</p>
              <p className="body-medium">Ваша речь запутанна и нелогична</p>
            </li>
            <li className="section-for-whom__item">
              <p className="body-large section-for-whom__quote">Ну как бы, короче, типа...</p>
              <p className="body-medium">Вы хотите избавиться от слов паразитов</p>
            </li>
            <li className="section-for-whom__item section-for-whom__item--hide-on-mobile">
              <p className="body-large section-for-whom__quote">Надеюсь меня поняли</p>
              <p className="body-medium">Вы хотите увидеть и услышать себя со стороны</p>
            </li>
            <li className="section-for-whom__item">
              <p className="body-large section-for-whom__quote">Почему меня игнорируют?</p>
              <p className="body-medium">Вы хотите чтобы Ваше мнение услышали</p>
            </li>
            <li className="section-for-whom__item">
              <p className="body-large section-for-whom__quote">Надо потренироваться</p>
              <p className="body-medium">Вы хотите лучше выглядеть перед камерой</p>
            </li>
          </ul>
        </div>
      </div>
      {/* / for whom */}

      {/* how it works */}
      <HowItWorksSection />
      {/* / how it works */}

      {/* categories */}
      <div className="section-categories">
        <div className="section-inner vertical-indent--m">
          <div className="section-categories__header">
            <h2>Популярные категории вопросов</h2>
            <p
              className="body-medium section-categories__link"
              onClick={() => goToCategories('landing-all-categories')}
            >
              Все категории ›
            </p>
          </div>
          <HorizontalList>
            {blogger.dud}
            {blogger.pozner}
            {english.sparkFunEn}
            {relationship.firstDate}
            {relationship.aboutPartner}
          </HorizontalList>
        </div>
      </div>
      {/* / categories */}

      <hr />

      {/* counter */}
      <div className="section-counter">
        <div className="section-inner vertical-indent--m">
          <div className="section-counter__content">
            <LogoImage />
            <AnswerCounter className="section-counter__counter" />
            <div className="section-counter__text">
              <h3 className="section-counter__message">
                Столько раз пользователи ответили на вопросы в нашем тренажере.
              </h3>
              <h3 className="section-counter__message">А на какие вопросы ответите Вы?</h3>
            </div>
            <Button size="large" onClick={() => goToCategories('landing-try-button-bottom')}>
              Попробовать
            </Button>
          </div>
        </div>
      </div>
      {/* / counter */}
    </Layout>
  )
}

const HowItWorksSection = () => {
  const sectionInnerRef = useRef(null)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const [wasStarted, setWasStarted] = useState(false)

  // Numbers from the initial desing.
  // These values will be recalculated based on section-inner width using inital width/height ratio (320 / 520 = 0.615)
  const MAX_WIDTH_PX = 540
  const MAX_HEIGHT_PX = 344
  const [storiesComponentWidth, setStoriesComponentWidth] = useState(MAX_WIDTH_PX)
  const [storiesCOmponentHeight, setStoriesComponentHeight] = useState(MAX_HEIGHT_PX)

  useEffect(() => {
    if (sectionInnerRef?.current) {
      const heightWidthRatio = MAX_HEIGHT_PX / MAX_WIDTH_PX
      const innerSectionWidth = sectionInnerRef?.current.clientWidth

      let newStoriesWidth
      let newStoriesHeight

      if (document.body.clientWidth > 767) {
        newStoriesWidth = sectionInnerRef?.current.clientWidth / 2
        newStoriesHeight = newStoriesWidth * heightWidthRatio
      } else {
        newStoriesWidth = innerSectionWidth
        newStoriesHeight = innerSectionWidth * heightWidthRatio
      }

      setStoriesComponentWidth(newStoriesWidth)
      setStoriesComponentHeight(newStoriesHeight)
    }
  }, [sectionInnerRef?.current?.clientWidth])

  const stepTextItems = [
    'Подключаем микрофон и камеру',
    'Тренажер задает Вам вопрос - Вы отвечаете',
    'Вы смотрите запись ответа и перезаписываете его, если не понравилось',
    'После пяти вопросов мы показываем Вам статистику и записи всех ответов',
  ]

  const Step = ({ children }) => {
    return <div className="section-how-it-works__story">{children}</div>
  }

  const Step1Content = ({ action }) => {
    // A hack to launch stories when they are getting visible fist time
    // It could be achieved with "isPaused" Stories property but for some reason it doesn't work.
    // That's why it's required to at first pause it and play it later. And for some reason it didn't work without timeouts
    useEffect(() => {
      if (wasStarted) return

      if (!isVisible) {
        setTimeout(() => action('pause'), 0)
      } else {
        setWasStarted(true)
        setTimeout(() => action('play'), 0)
      }
    }, [isVisible])

    return (
      <Step>
        <TrainerStep1Image />
      </Step>
    )
  }

  const Step2Content = () => (
    <Step>
      <TrainerStep2Image />
    </Step>
  )

  const Step3Content = () => (
    <Step>
      <TrainerStep3Image />
    </Step>
  )

  const Step4Content = () => (
    <Step>
      <TrainerStep4Image />
    </Step>
  )

  const stories = [
    {
      content: Step1Content,
    },
    {
      content: Step2Content,
    },
    {
      content: Step3Content,
    },
    {
      content: Step4Content,
    },
  ]

  const onClickStep = stepIndex => {
    setCurrentStepIndex(stepIndex)
  }

  const generateSteps = () => {
    return stepTextItems.map((text, index) => {
      const stepClass = classNames('body-medium section-how-it-works__step', {
        'body-medium section-how-it-works__step--active': index === currentStepIndex,
      })

      return (
        <p key={index} className={stepClass} onClick={() => onClickStep(index)}>
          {index + 1}. {text}
        </p>
      )
    })
  }

  const onStoryStart = startedStoryIndex => {
    setCurrentStepIndex(startedStoryIndex)
  }

  const onStoryEnd = endedStepIndex => {
    setCurrentStepIndex((endedStepIndex + 1) % stepTextItems.length)
  }

  const onVisibilityChange = isVisible => {
    // start showing stories from beginning when component is getting visible
    if (isVisible) {
      setIsVisible(true)
    }
  }

  return (
    <div className="section-how-it-works section-outer--gray">
      <div ref={sectionInnerRef} className="section-inner vertical-indent--m">
        <div className="section-how-it-works__description">
          <h2>Как работает наш тренажер</h2>
          <div className="section-how-it-works__steps-list">{generateSteps()}</div>
        </div>
        <VisibilitySensor onChange={onVisibilityChange}>
          <div className="section-how-it-works__stories-container">
            <Stories
              stories={stories}
              onStoryStart={onStoryStart}
              onStoryEnd={onStoryEnd}
              currentIndex={currentStepIndex}
              defaultInterval={5000}
              width={storiesComponentWidth}
              height={storiesCOmponentHeight}
              loop
            />
            <div className="section-how-it-works__security">
              <div className="section-how-it-works__security__icon">
                <IconEyeClosed />
              </div>
              <div className="section-how-it-works__security__text">
                <p className="body-medium">Боитесь, что кто-то увидит Ваши видео?</p>
                <p className="body-small">
                  Мы знаем это чувство! Не волнуйтесь, мы не сохраняем Ваши видео у себя. Все видео хранятся в рамках
                  Вашего браузера до момента завершения интервью или закрытия страницы и не передаются куда-либо еще.
                </p>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    </div>
  )
}

const AnswerCounter = ({ className }) => {
  const initialCounter = useMemo(() => {
    const mlsAfterLaunch = Date.now() - new Date(2021, 4, 5).getTime()
    const answered = Math.floor(mlsAfterLaunch / (60 * 1000))
    return answered
  }, [])

  const [counter, setCounter] = useState(initialCounter)
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(c => c + 1)
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])
  return <span className={className}>{counter}</span>
}

export default Home
