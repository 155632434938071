import React from 'react'
import CategoryCard from '../components/CategoryCard.jsx'

const blogger = {
  dud: <CategoryCard title="Дудь" sampleQuestion="Сколько. Ты. Зарабатываешь?" category="dud" />,
  pozner: (
    <CategoryCard title="Познер" sampleQuestion="Оказавшись перед Богом, что Вы ему скажете?" category="pozner" />
  ),
}

const english = {
  sparkFunEn: (
    <CategoryCard
      title="Spark fun"
      sampleQuestion="If you had a theme song, what would it be?"
      category="spark-fun-en"
    />
  ),
}

const relationship = {
  firstDate: (
    <CategoryCard title="Первое свидание" sampleQuestion="Ты считаешь себя романтиком?" category="first-date" />
  ),
  aboutLove: (
    <CategoryCard title="О любви" sampleQuestion="Вы верите в любовь с первого взгляда?" category="about-love" />
  ),
  aboutPartner: (
    <CategoryCard title="О партнере" sampleQuestion="Кто умнее – Вы или Ваш партнер?" category="about-partner" />
  ),
  aboutMarriage: (
    <CategoryCard title="О свадьбе и браке" sampleQuestion="Что самое лучшее есть в браке?" category="about-marriage" />
  ),
}

export { blogger, english, relationship }
